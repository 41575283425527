import React, { Component } from 'react'
import { Form, Button, message, Divider } from 'antd'
import { compose } from "recompose"
import { withApollo } from "@apollo/client/react/hoc"
import gql from 'graphql-tag'
import { withRouter, Link } from 'react-router-dom'
import { FormattedMessage ,injectIntl } from 'react-intl'
import { loginUser } from '../../auth'
import { inject, observer } from 'mobx-react'
import {
  TextField
} from '@material-ui/core'
import styled from 'styled-components'
const FormItem = Form.Item

const currentUser = gql`
  query currentUser {
    currentUser {
      _id
      email
      profile {
        _id
        imageUrl
        firstName
        lastName
        birthDate
        gender
      }
    }
  }
`

const StyledTextField = styled(TextField)`
  label + .MuiInput-formControl {
    margin-top: unset;
  }
  .ant-form label {
    font-size: 14px;
    color: #bfbfbf;
    font-weight: bold;
  }
`
function hasErrors (fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}
@inject('i18n')
@observer
@injectIntl
class LoginForm extends Component {
  state = {
    loginLoading: false,
    email: '',
    password: '',
    disabled: true
  }

  checkPassword = (rule, value, callback) => {
    if (value) {
      if (value.length > 7) {
        callback()
      } else {
        callback('Your password too short!')
      }
    }
  }

  handleResetPassword = () => {
    this.props.history.push('/password/reset')
    this.props.onClose()
  }

  handleLogin = e => {
    e.preventDefault()
    const { validateFields } = this.props.form
    const { intl } = this.props
    validateFields((err, values) => {
      // console.log(err)
      if (!err) {
        this.setState({
          loginLoading: true
        })
        loginUser(values).then(res => {
          if (res.error) {
            this.setState({
              loginLoading: false
            })
            if (res.error.message.search('Authentication not possible. No salt value stored in mongodb collection!') > -1) {
              const msg = `${values.email} ${intl.formatMessage({ id : "app.loginWithFacebook"})} \n ${intl.formatMessage({ id : "app.registered.facebook"})}`
              return message.error(msg, 15)
            }
            else if (res.error.message.search('incorrect username') > -1) {
              const msg = `${values.email} ${intl.formatMessage({ id : "app.incorrect.email"})}`
              return message.error(msg, 15)
            }
            return message.error(res.error.message, 10)
          }
          // console.log('login response', res)
          this.props.client
            .query({
              query: currentUser,
              fetchPolicy: 'network-only'
            })
            .then(data => {
              this.setState(
                {
                  loginLoading: false
                  // visible: false
                },
                this.props.onClose
              )
              this.props.client.reFetchObservableQueries()
              const { from } = this.props.location.state || {
                from: { path: '/dashboard' }
              }
              this.props.history.push(from)
              this.props.form.resetFields()
              window.scrollTo(0, 0)
              window.location.reload()
            })
          /*
            this.props.client.writeQuery({
                query: currentUser,
                data: {
                currentUser: res.data
                }
            })
            console.log('read', this.props.client.readQuery({
                query: currentUser
            }))
            // .then(data => {
                // console.log('login', data)
            this.props.history.push('/dashboard')
            // })
    */
        })
      }
    })
  }

  render = () => {
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched,
      isFieldsTouched
    } = this.props.form
    const { intl} = this.props
    const email = isFieldTouched('email') && getFieldError('email')
    // const password = isFieldTouched('password') && getFieldError('password')
    return <Form onSubmit={this.handleLogin}>
      <Divider style={{ marginTop: 50 }} />
      <div className="lineLoginTitle">
        <div className="loginModalTitle">
          <span>
            {intl.formatMessage({ id : "app.Or"})}
          </span>
        </div>
      </div>
      <div className="loginTextField" style={{ textAlign: 'left' }}>
        <FormItem validateStatus={email ? 'error' : ''} help={email || ''} hasFeedback>
          {getFieldDecorator('email', {
            initialValue: '',
            // normalize: (value) => (value.trim()),
            normalize: (value) => (value ? value.replace(/[^\w!@#$%^&*()_+=\-."'<>?\\/]+/g, '') : '').toLowerCase(),
            rules: [
              {
                type: 'string',
                message: 'The input is not valid E-mail!',
                // eslint-disable-next-line no-useless-escape
                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              },
              {
                required: true,
                message: 'Please input your E-mail!'
              }
            ]
          // })(<Input placeholder="Email" />)}
          })(<StyledTextField
            fullWidth
            label={intl.formatMessage({ id : "app.email"})}
          />)}
        </FormItem>
      </div>
      <div className="loginTextField" style={{ textAlign: 'left' }}>
        <FormItem hasFeedback>
          {getFieldDecorator('password', {
            initialValue: '',
            rules: [
              {
                required: true,
                message: 'Please enter your password!'
              },
              {
                validator: this.checkPassword
              }
            ]
          // })(<Input type="password" placeholder="Password" />)}
          })(<StyledTextField
            fullWidth
            type="password"
            label={intl.formatMessage({ id : "app.password"})}
          />)}
        </FormItem>
      </div>
      <div className="loginTextField" style={{ textAlign: 'center' }} >
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          fullWidth
          loading={this.state.loginLoading}
          style={{
            padding: '0 30px',
            borderRadius: 0,
            width: '100%'
          }}
          disabled={hasErrors(getFieldsError()) || !isFieldsTouched(
            ['email']
          )}>
            {intl.formatMessage({ id : "app.login"})}
        </Button>
      </div>
      <div className="loginTextField" style={{ marginTop: 16 }}>
        <span>
          <span className="aLink" onClick={this.handleResetPassword}>
            <FormattedMessage id="app.forgotPassword" />
          </span>

        </span>
      </div>
      <div style={{textAlign: 'left'}}>
        <Link className="privacy-policy-login" to="/privacy-policy">Privacy Policy</Link>
      </div>
    </Form>
  }
}

export default compose(
  Form.create(),
  withApollo,
  withRouter // TODO: should put router only inside auth wrapper
)(LoginForm)
